<template>
  <div id="app" class="app">
    <Toast />
    <DynamicDialog />
    <ConfirmDialog group="removeProduct">
      <template #container="{ message, acceptCallback }">
        <div
          class="flex flex-column align-items-center p-5 surface-overlay border-round">
          <div
            class="border-circle bg-red-500 inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
            <i class="pi pi-exclamation-triangle text-5xl text-white"></i>
          </div>
          <span class="font-bold text-2xl block mb-2 mt-4">{{
            message.header
          }}</span>
          <p class="mb-0">Dieses Produkt muss raus!</p>
          <p class="mb-0">
            Bitte bestätigen Sie, dass das Produkt
            <span class="font-bold text-xl p-2 bg-red-200">{{
              message.weight
            }}</span>
            entfernt wurde.
          </p>
          <p></p>
          <div v-if="store.pinRequired" class="w-7">
            <div>
              Geben Sie aus Sicheheitsgründen die Pin
              <span class="font-bold">{{ confirmPin }}</span> ein um zu
              vermeiden, das versehentlich auf Bestätigen geklickt wird.
            </div>
            Pin:
            <div class="card flex justify-content-center">
              <InputOtp v-model="confirmedPin" integerOnly />
            </div>
          </div>
          <div class="flex align-items-center gap-2 mt-4">
            <Button
              :disabled="!pinCorrect && store.pinRequired"
              label="Bestätigen"
              outlined
              severity="danger"
              @click="acceptCallback"
              class="w-8rem"></Button>
          </div>
        </div>
      </template>
    </ConfirmDialog>
    <ConfirmDialog group="planDone">
      <template #container="{ message, acceptCallback, rejectCallback }">
        <div
          class="flex flex-column align-items-center p-5 surface-overlay border-round">
          <div
            class="border-circle bg-green-500 inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
            <i class="pi pi-verified text-5xl text-white"></i>
          </div>
          <span class="font-bold text-2xl block mb-2 mt-4">{{
            message.header
          }}</span>
          <p class="mb-0">Die Planmenge wurde abgewogen</p>
          <p class="mb-0">Möchten Sie dennoch fortfahren?</p>
          <p></p>
          <div class="flex align-items-center gap-2 mt-4">
            <Button
              label="Fortfahren"
              outlined
              severity="info"
              @click="acceptCallback"
              class="w-8rem"></Button>
            <Button
              label="Beenden"
              :disabled="mittelwert <= store?.data?.product?.productWeight"
              outlined
              severity="success"
              @click="rejectCallback"
              class="w-8rem"></Button>
          </div>
        </div>
      </template>
    </ConfirmDialog>
    <ConfirmDialog group="forceStop">
      <template #container="{ message, acceptCallback, rejectCallback }">
        <div
          class="flex flex-column align-items-center p-5 surface-overlay border-round">
          <div
            class="border-circle bg-red-500 inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
            <i class="pi pi-times text-5xl text-white"></i>
          </div>
          <span class="font-bold text-2xl block mb-2 mt-4">{{
            message.header
          }}</span>
          <p class="mb-0">Beenden erzwingen</p>
          <p class="mb-0">Sind Sie sich sicher?</p>
          <p></p>
          <div class="flex align-items-center gap-2 mt-4">
            <Button
              label="Beenden erzwingen"
              outlined
              severity="danger"
              @click="acceptCallback"
              class="w-8rem"></Button>
            <Button
              label="Abbruch"
              outlined
              severity="success"
              @click="rejectCallback"
              class="w-8rem"></Button>
          </div>
        </div>
      </template>
    </ConfirmDialog>
    <div class="p-m-4">
      <span class="flex justify-content-between">
        <span
          class="text-4xl cursor-pointer"
          @click="forceStop()"
          @dblclick="console.log(forceStopCounter++)">
          Scale Measurement Data
          <span class="text-xs font-light">Version 0.1a © 2024 A. Catal</span>
        </span>
        <span class="flex align-items-center"
          ><span class="pl-2"
            >Sound
            <span v-if="unMuteSound" class="pl-1 pi pi-volume-up"></span>
            <span v-else class="pl-1 pi pi-volume-off"></span>
          </span>
          <span class="pl-2"
            ><InputSwitch v-model="unMuteSound" :binary="true" /></span
        ></span>
        <span class="flex align-items-center"
          ><span class="pl-2">Pin Bestätigung </span>
          <span class="pl-2"
            ><InputSwitch v-model="store.pinRequired" :binary="true" /></span
        ></span>
        <Button
          text
          class="pl-2"
          label="Charge-Liste"
          @click="openChargeList()" />
      </span>
      <ul id="scaleDataList"></ul>

      <h2 class="text-blue-600">Produkt- und Chargendaten</h2>
      <div v-if="!store.start" class="grid">
        <div class="">
          <label for="charge" class="pr-3 pl-2">Charge</label>
          <InputText id="charge" v-model="store.formData.chargeName" />
        </div>
        <div class="pl-4">
          <label for="pass" class="pr-3 pl-2">Verbindungs-Passwort</label>
          <Password v-model="store.credentials.passcode" :feedback="false" />
        </div>
        <div
          :class="store.formData.chargeName == '' ? 'p-disabled' : ''"
          @click="starteCharge()"
          class="mt-4 p-3 col-12 flex justify-content-center p-3 bg-green-200 font-light border-round cursor-pointer">
          Charge Starten
        </div>
      </div>
      <div class="grid" v-if="store.start">
        <div class="col-4">
          <div class="p-grid p-formgrid p-fluid">
            <div class="p-field p-col-12 p-md-6">
              <label for="charge">Charge</label>
              <InputText
                id="charge"
                v-model="store.formData.chargeName"
                disabled />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="mhd">MHD</label>
              <Calendar
                :disabled="store.processStarted"
                type="date"
                :showTime="false"
                v-model="store.data.mhd"
                :showOnFocus="true"
                ref="calendar"
                dateFormat="dd-mm-yy"
                @update:model-value="formatDate($event)"
                placeholder="01-01-2033"
                highlightOnFocus />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="durchgefuehrtVon">Durchgeführt von</label>
              <Dropdown
                :disabled="store.processStarted"
                id="durchgefuehrtVon"
                :options="users"
                optionLabel="userName"
                v-model="store.data.durchgefuehrtVon" />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="produkt">Produkt</label>
              <Dropdown
                :disabled="store.processStarted"
                id="produkt"
                :options="products"
                optionLabel="productName"
                v-model="store.data.product"
                @update:model-value="selectProduct($event)" />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="planMenge">Plan-Menge</label>
              <InputNumber
                suffix=" Stück"
                highlightOnFocus
                id="planMenge"
                @update:model-value="
                  store.data.istMenge = $event;
                  calculateMengen();
                "
                v-model="store.data.planMenge" />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="istMenge">ist-Menge</label>
              <InputNumber
                suffix=" Stück"
                highlightOnFocus
                id="istMenge"
                @update:model-value="calculateMengen()"
                v-model="store.data.istMenge" />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="gewogen">gewogen</label>
              <InputNumber
                disabled
                suffix=" Stück"
                highlightOnFocus
                id="gewogen"
                v-model="store.data.gewogen" />
            </div>
            <div class="p-field p-col-12 p-md-6">
              <label for="zuWiegen">Zu wiegen</label>
              <InputNumber
                disabled
                suffix=" Stück"
                highlightOnFocus
                id="zuWiegen"
                v-model="store.data.zuWiegen" />
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="mt-4 text-2xl grid font-semibold">
            <div class="col-12 flex justify-content-center">
              {{ currentDate.Datum }}
            </div>
            <div class="col-12 flex justify-content-center">
              {{ currentDate.Uhrzeit }}
            </div>
            <div class="mt-4 col-12 flex justify-content-center">
              TU1: {{ store.tuValue }}g
            </div>
            <div class="mt-4 col-12 flex justify-content-center">
              <span
                class="flex align-items-center"
                :class="
                  mittelwert >= store?.data?.product?.productWeight
                    ? 'text-green-400'
                    : 'text-red-400'
                "
                ><span>Mittelwert: </span
                ><span class="text-4xl pl-2">{{ mittelwert }}g</span></span
              >
            </div>
            <div class="flex justify-content-between w-full">
              <span class="text-sm">Status: {{ store.currentState }}</span>
            </div>
            <div
              v-if="store.processStarted"
              class="mt-2 p-3 col-12 flex justify-content-center font-light border-round bg-green-200 cursor-pointer">
              {{ store.currentSaldo }}g
            </div>
            <div v-else class="col-12">
              <div
                v-if="
                  store.data.weighings.length == 0 &&
                  store.formData.chargeData.length == 0
                "
                class="mt-2 p-3 flex justify-content-center p-3 bg-red-200 font-light border-round cursor-pointer"
                @click="resetData()">
                Abbrechen
              </div>
              <div
                v-else
                class="mt-2 p-3 flex justify-content-center p-3 bg-green-200 font-light border-round cursor-wait"
                @click="saveAndNewCharge()"
                :class="
                  store.formData.chargeData.length == 0 ? 'p-disabled' : ''
                ">
                Speichern und Neue Charge
              </div>
            </div>

            <div
              v-if="!store.processStarted"
              class="mt-4 p-3 col-12 flex justify-content-center p-3 bg-green-200 font-light border-round cursor-pointer"
              @click="startProcess()">
              Start
            </div>
            <div
              v-if="store.processStarted"
              class="mt-4 p-3 col-12 flex justify-content-center p-3 bg-red-200 font-light border-round cursor-wait"
              :class="{
                'p-disabled':
                  store.processStarted &&
                  (store.data.zuWiegen > 0 ||
                    mittelwert <= store?.data?.product?.productWeight),
              }"
              @click="stopProcess()">
              Beenden
            </div>
          </div>
        </div>
        <div class="col-4">
          <ScrollPanel ref="scrollPanel" style="width: 100%; height: 600px">
            <DataTable :value="store.data.weighings" responsiveLayout="scroll">
              <Column field="counter" header="Nr." class="text-center">
                <template #body="{ data }">
                  {{ data.counter }}
                </template>
              </Column>
              <Column
                field="timestamp"
                header="Zeitstempel"
                footer="Zeitstempel">
                <template #body="{ data }">
                  {{ data.timestamp }}
                </template>
              </Column>
              <Column field="weight" header="Gewicht" footer="Gewicht" class="">
                <template #body="{ data }">
                  <div
                    v-if="checkDataWeight(data.measurement) == 0"
                    class="p-2 bg-red-500 text-bold text-white">
                    {{ data.measurement }}
                  </div>
                  <div
                    v-if="checkDataWeight(data.measurement) == 1"
                    class="p-2 bg-green-500">
                    {{ data.measurement }}
                  </div>
                  <div
                    v-if="checkDataWeight(data.measurement) == 2"
                    class="p-2 bg-yellow-500 text-gray font-semibold">
                    {{ data.measurement }}
                  </div>
                </template>
              </Column>
            </DataTable>
          </ScrollPanel>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, defineAsyncComponent, computed } from "vue";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useDialog } from "primevue/usedialog";
import webstomp from "webstomp-client";
import { userStore } from "@/store/userStore";
import axios from "axios";

const ChargeList = defineAsyncComponent(() => import("@/views/ChargeList.vue"));

const store = userStore();
const toast = useToast();
const confirm = useConfirm();
const dialog = useDialog();
const scrollPanel = ref(); //Referenz zum Scrollpanel zum steuern dieser

//berechnet den Mittelwert aller Messungen
const mittelwert = computed(() => {
  let sum = 0;
  store.data.weighings.forEach((element) => {
    sum += element.measurement;
  });
  if (!sum) return (0).toFixed(2);
  return (sum / store.data.weighings.length).toFixed(2);
});

const now = new Date();
const currentDate = {
  Datum: now.toLocaleDateString("de-DE"), // Format: Tag/Monat/Jahr für Deutschland
  Uhrzeit: now.toLocaleTimeString("de-DE"), // Format: Stunden:Minuten:Sekunden
};

const users = ref();
const products = ref();
const password = ref();
const confirmPin = ref();
const confirmedPin = ref();

const werteLaden = () => {
  const url = "https://stagingapi.famtura.de/itqan/api/scaler-data";
  axios
    .get(url)
    .then((response) => {
      products.value = response.data.products;
      users.value = response.data.users;
      password.value = response.data.password;
      store.selectedProduct = response.data.selectedProduct;
      //  console.log(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
};

// Funktion zum Formatieren des Datums ist nötig um ein Komflikt zwischen OS/Browser und
// GMT speicherung zu vermeiden, weil sonst das Datum um 1 Tag verschoben wird
const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Monate sind 0-basiert
  const year = date.getFullYear();
  store.data.mhd = `${day}-${month}-${year}`;
};

onMounted(() => {
  werteLaden();
  if (!client.connected) connectToRabbitMQ();
});

const lockUpTable = [
  { grenze: 100, messen: "N" }, // Für N < 100
  { grenze: 500, messen: 50 }, // Für 100 <= N <= 500
  { grenze: 3200, messen: 80 }, // Für 500 < N <= 3200
  { grenze: 10000, messen: 125 }, // Für 500 < N <= 3200
  { grenze: Infinity, messen: 160 }, // Für N > 10000
];

const bestimmeZuWiegen = (N) => {
  for (let i = 0; i < lockUpTable.length; i++) {
    if (N < lockUpTable[i].grenze) {
      return lockUpTable[i].messen === "N" ? N : lockUpTable[i].messen;
    }
  }
  // Sicherheitsrückfall, sollte nie erreicht werden, da der letzte Grenzwert Infinity ist
  return 0;
};

const calculateMengen = () => {
  //  store.data.zuWiegen = 0;
  store.data.zuWiegen =
    bestimmeZuWiegen(store.data.istMenge) - store.data.gewogen;
};

// Sound für Fehlerhaftes Produkt
const errorSound = new Audio(require("@/assets/error.mp3"));
// Sound für gültiges Produkt
const beepSound = new Audio(require("@/assets/beep.mp3"));
const unMuteSound = ref(false);

const exchange = "scaler"; // Name des Exchanges
const queueName = ""; // Eine temporäre Queue wird verwendet
//const routingKey = ""; // Routing Key für den Fanout Exchange ist leer

const receivedMessages = ref([]); // Zum Speichern empfangener Nachrichten

const selectProduct = (selection) => {
  store.data.product = selection;
  store.tuValue = store.data.product.productTuValue;
  store.toleranzBereich =
    store.data.product.productWeight - store.data.product.productTuValue;
  console.log(
    "🚀 ~ file: App.vue:439 ~ store.tolleranzBereich:",
    store.toleranzBereich
  );
};

//Verbingsversuche
let reconnectAttempts = 0;

// Verbindungsdaten
const rabbitMQUrl = "wss://stagingapi.famtura.de/ws/"; // WebSocket URL für RabbitMQ

// Funktion zum Planen eines Reconnects mit exponentiellem Backoff
const scheduleReconnect = (immediate) => {
  if (store.credentials.passcode === "") {
    console.log("Password empty",new Date());
    return;
  }
  if (immediate) {
    reconnectAttempts = 0;
  }
  let delay = Math.min(10000, 500 * Math.pow(2, reconnectAttempts)); // Exponentieller Backoff, max. 10 Sekunden
  setTimeout(() => {
    reconnectAttempts++;
    connectToRabbitMQ();
  }, delay);
};

const client = webstomp.client(rabbitMQUrl, {
  debug: false,
  heartbeat: { outgoing: 5000, incoming: 43200000 }, // Setzt outgoing auf 5 sek. und incoming auf 600 sek. Heartbeat-Intervalle ein
});
// Funktion zum Verbinden mit RabbitMQ über WebSocket
const connectToRabbitMQ = () => {
  if (client.connected) {
    // Wenn bereits verbunden, dann zuerst trennen
    client.disconnect(() => {
      console.log("Disconnected from RabbitMQ before reconnecting",new Date());
    });
  }

  client.connect(
    store.credentials.login,
    store.credentials.passcode,
    () => {
      console.log("Connected to RabbitMQ via WebSocket",new Date());
      reconnectAttempts = 0; // Zurücksetzen der Reconnect-Versuche bei erfolgreicher Verbindung

      // Abonnieren einer Queue, um Nachrichten zu empfangen
      client.subscribe(`/exchange/${exchange}/${queueName}`, (message) => {
        if (message.body && store.processStarted) {
          //Konsolenausgabe der empfangenen Nachricht zur Kontrolle
          //console.log("Received message:", message.body);

          const receivedData = JSON.parse(message.body);
          receivedData.counter = store.data.weighings.length + 1;

          //Überprüfen des Gewichtes und hinzufügen in die Liste, wenn es akzeptabel ist sonst lass das Produkt entfernen und mache erst weiter wenn es entfernt wurde
          store.currentSaldo = receivedData.measurement;
          let state = checkDataWeight(receivedData.measurement);
          let work = true;
          if (state === 0) {
            work = confirmProductRemoved(receivedData.measurement);
          }
          if (work) {
            store.data.weighings.unshift(receivedData); // Hinzufügen der empfangenen Nachricht in die Liste in umgekehrter Reihenfolge gegenteil von push
            store.data.zuWiegen--;
            store.data.gewogen++;
            if (store.data.zuWiegen === 0) {
              confirmWorkContinue();
            }
          }
          receivedMessages.value.unshift(message.body);
          if (store.data.zuWiegen === 0) {
            store.currentState = "Fertig";
          } else {
            store.currentState = "Prozess läuft";
          }
        }
      });
    },
    // eslint-disable-next-line
    (error) => {
      if (error.type == "close" && error.reason == "") {
        scheduleReconnect(); // Versuchen, die Verbindung erneut herzustellen
        return; //Verbindungsabbruch durch inaktiviät
      }
      if (error.type == "close" && error.reason == "STOMP died") {
        console.log(
          "Connection closed. Attempting to reconnect, because STOMP died...",new Date()
        );
        if (!client.connected) //Verbindung sicherheitshalber beenden
        scheduleReconnect(true); // Versuchen, die Verbindung erneut herzustellen

        return;
      }
      console.error("Connection error:" + new Date(), error);
      scheduleReconnect(); // Versuchen, die Verbindung erneut herzustellen
    }
  );
  client.onclose = () => {
    console.log("Connection closed. Attempting to reconnect...",new Date());
    scheduleReconnect(); // Versuchen, die Verbindung erneut herzustellen
  };
};

// Funktion zum Trennen der Verbindung
/* const disconnectFromRabbitMQ = () => {
  if (client !== null && client.connected) {
    client.disconnect(() => {
      console.log("Disconnected from RabbitMQ");
      // Hier können  weitere Aufräumarbeiten eingetragen werden
    });
  }
}; */

// Formularschema
const formDataShema = {
  product: {},
  weighings: [],
  mhd: "",
  durchgefuehrtVon: "",
  planMenge: 0,
  gewogen: 0,
  istMenge: 0,
  zuWiegen: 0,
};

const openChargeList = () => {
  dialog.open(ChargeList, {
    props: {
      header: "Charge Liste",
      modal: true,
      style: {
        width: "70vw",
      },
    },
  });
};

const resetData = () => {
  store.formData.chargeData = [];
  store.start = false;
  store.formData.chargeName = "";
  store.data.mhd = "";
  store.data.durchgefuehrtVon = null;
  store.data.product = null;
  store.data.planMenge = 0;
  store.data.gewogen = 0;
  store.data.zuWiegen = 0;
  store.tuValue = 0;
  store.currentSaldo = 0;
  store.currentState = "";
  store.data.weighings = [];
  store.processStarted = false;
  store.credentials.passcode = "";
  werteLaden();
};

const saveAndNewCharge = () => {
  saveAndSendData();
  resetData();
};

let forceStopCounter = 0;
const forceStop = () => {
  if (forceStopCounter > 4) {
    confirmForceStop();
  }
};

const starteCharge = () => {
  if (store.credentials.passcode === "") {
    toast.add({
      severity: "error",
      summary: "Fehler",
      detail: "Bitte Passwort eingeben",
      life: 3000,
    });
    return;
  }
  if (store.credentials.passcode !== password.value) {
    toast.add({
      severity: "error",
      summary: "Fehler",
      detail: "Passwort falsch",
      life: 3000,
    });
    return;
  }
  store.start = true;
  connectToRabbitMQ(); //Verbindung zum RabbitMQ Server
};

const startProcess = () => {
  if (validate()) {
    store.processStarted = true;
    store.data.gewogen = 0;
    store.data.weighings = [];
  } else return;
  // Verbindung zum RabbitMQ Server
};

const stopProcess = async () => {
  store.data.mittelwert = mittelwert.value; // zuweisen des Mittelwertes ins store zum späteren speichern und senden an das BE
  setTimeout(() => {
    //asyncroner Prozess um die Daten zu speichern und die Form zurückzusetzen
    store.formData.chargeData.push(JSON.parse(JSON.stringify(store.data)));
    store.data = JSON.parse(JSON.stringify(formDataShema));
    store.processStarted = false;
    store.currentState = "Fertig";
  }, 10);
};

/**
 * Überprüft das Gewicht eines Produkts im Vergleich zum Zielgewicht (store.tuValue) und einem Toleranzbereich.
 * Es werden drei Zustände zurückgegeben, die den Status des Gewichts repräsentieren:
 * - 1: Das Gewicht liegt über dem Zielgewicht zuzüglich des Toleranzbereichs, was als akzeptabel gilt.
 * - 2: Das Gewicht liegt unter dem Zielgewicht, aber noch innerhalb des Toleranzbereichs.
 * - 0: Das Gewicht liegt unter dem Zielgewicht abzüglich des Toleranzbereichs, was als nicht akzeptabel gilt.
 *   In diesem Fall sollte eine Aktion ausgeführt werden, um das Produkt zu entfernen (hier auskommentiert).
 *
 * @param {number} weight - Das zu prüfende Gewicht des Produkts.
 * @returns {number} - Den Zustand des Gewichts: 1 für akzeptabel, 2 für innerhalb des Toleranzbereichs, 0 für nicht akzeptabel.
 */
const checkDataWeight = (weight) => {
  if (weight >= store.tuValue + store.toleranzBereich) {
    if (unMuteSound.value) beepSound.play();
    return 1;
  } else if (weight < store.tuValue) {
    if (unMuteSound.value) errorSound.play();
    return 0;
  } else {
    if (unMuteSound.value) beepSound.play();
    return 2;
  }
};

// Pin-Überprüfung
const pinCorrect = computed(() => {
  return confirmedPin.value == confirmPin.value;
});

const confirmProductRemoved = (weight) => {
  confirmPin.value = generatePin();
  confirm.require({
    group: "removeProduct",
    header: "STOP",
    defaultFocus: "accept",
    weight: weight,
    accept: () => {
      if (pinCorrect.value || !store.pinRequired) {
        confirmedPin.value = "";
        toast.add({
          severity: "info",
          summary:
            "Produkt mit dem Gewicht von > " +
            weight +
            "g " +
            " < wurde entfernt",
          detail: "Arbeit wird fortgesetzt",
          life: 3000,
        });
        return true;
      } else {
        confirmedPin.value = "";
        toast.add({
          severity: "error",
          summary: "Fehler beim Bestätigen",
          detail: !pinCorrect.value ? " Pin fehlerhaft" : "",
          life: 3000,
        });
        return false;
      }
    },
    reject: () => {
      confirmedPin.value = "";
      pinCorrect.value = false;
      toast.add({
        severity: "error",
        summary: "Abgebrochen",
        detail: "Vorgang abgebrochen",
        life: 3000,
      });
    },
  });
};

const confirmWorkContinue = (weight) => {
  confirm.require({
    group: "planDone",
    header: "Fertig",
    defaultFocus: "accept",
    weight: weight,
    accept: () => {
      toast.add({
        severity: "info",
        summary: "Messen wird fortgesetzt",
        detail: "Arbeit wird fortgesetzt, es kann jederzeit gestoppt werden",
        life: 3000,
      });
      return false;
    },
    reject: () => {
      stopProcess();
      confirmedPin.value = "";
      pinCorrect.value = false;
      toast.add({
        severity: "success",
        summary: "Beendet",
        detail: "Vorgang abgeschlossen",
        life: 3000,
      });
    },
  });
};

const confirmForceStop = (weight) => {
  confirm.require({
    group: "forceStop",
    header: "Beenden erzwungen",
    defaultFocus: "reject",
    weight: weight,
    accept: () => {
      store.processStarted = false;
      store.data.zuWiegen = 0;
      store.currentState = "Beenden erzwungen";
      forceStopCounter = 0;
      toast.add({
        severity: "danger",
        summary: "Beenden erzwungen",
        detail: "Arbeit wird beendet",
        life: 3000,
      });
    },
    reject: () => {
      toast.add({
        severity: "success",
        summary: "Beenden erzwingen Abgebrochen",
        detail: "Arbeit wird fortgesetzt",
        life: 3000,
      });
    },
  });
};

const saveAndSendData = () => {
  const url = "https://stagingapi.famtura.de/itqan/api/charge";
  axios
    .post(url, store.formData)
    .then((response) => {
      toast.add({
        severity: "success",
        summary: "Erfolgreich",
        detail: "Daten gespeichert",
        life: 3000,
      });
      console.log(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
};

const generatePin = () => {
  return Math.floor(Math.random() * 9000) + 1000;
};

const validate = () => {
  /* eslint-disable */
  //return true; //debug
  //Validierung der Eingabefelder
  if (store.formData.chargeName === "" || !store.formData.chargeName) {
    toast.add({
      severity: "error",
      summary: "Fehler",
      detail: "Bitte Charge eingeben",
      life: 3000,
    });
    return false;
  }
  if (store.data.mhd === "" || !store.data.mhd) {
    toast.add({
      severity: "error",
      summary: "Fehler",
      detail: "Bitte MHD eingeben",
      life: 3000,
    });
    return false;
  }
  if (store.data.durchgefuehrtVon === "" || !store.data.durchgefuehrtVon) {
    toast.add({
      severity: "error",
      summary: "Fehler",
      detail: "Bitte Durchgeführt von auswählen",
      life: 3000,
    });
    return false;
  }
  console.log("DEBUG: ", store.data.product);

  if (!store.data.product || !store.data.product?.productName) {
    toast.add({
      severity: "error",
      summary: "Fehler",
      detail: "Bitte Produkt auswählen",
      life: 3000,
    });
    return false;
  }
  if (store.data.planMenge <= 0 || !store.data.planMenge) {
    toast.add({
      severity: "error",
      summary: "Fehler",
      detail: "Bitte Plan-Menge eingeben",
      life: 3000,
    });
    return false;
  }

  return true;
};

//  code-Bereich zum überwachen ob sich das Gerät sich im Standby befand
// Um eine Funktionalität zu implementieren, die plattform- und browserübergreifend funktioniert,
// insbesondere für die Erkennung von Standby-Modus und Wiederaufnahme sowie die Überprüfung der Verbindungsstabilität,
// muss ein Ansatz gewählt werdne, der weitestgehend unabhängig von spezifischen APIs ist. Da direkte Ereignisse für
// Standby und Wiederaufnahme nicht konsistent über alle Plattformen und Browser hinweg verfügbar sind, können stattdessen
// regelmäßige "Herzschläge" (Heartbeats) verwenden, um die Verbindung zu überwachen.

// Ansatz:
// Heartbeat Timer einrichten: Ein regelmäßiger Timer sendet "Heartbeat"-Signal

// Überprüfung bei Wiederaufnahme: Wenn die Anwendung wieder in den Vordergrund kommt oder die Seite neu 
// fokussiert wird, überprüfen Sie, wie viel Zeit seit dem letzten erfolgreichen Heartbeat vergangen ist. 
// Ist diese Zeit signifikant länger als das Intervall des Heartbeat-Timers, gehen Sie davon aus,
// dass die Verbindung möglicherweise unterbrochen wurde und initiiert eine Überprüfung oder Wiederherstellung der Verbindung durch einen page refresh.
// Es wird davon ausgegangen, das der Browser im Standby-Modus keine Timer ausführt, daher wird die Zeit seit dem letzten Heartbeat als Indikator verwendet.
// Die Überprüfung erfolgt, wenn die Seite wieder sichtbar wird, indem die Funktion onResume aufgerufen wird.
// Die Überprüfung wird auch regelmäßig durchgeführt, indem der Heartbeat-Timer alle 10 Sekunden ausgeführt wird.
// Statt die Verbindung direkt neu aufzubauen, wird ein page refresh durchgeführt, wenn die Verbindung verloren geht. 
// Beim onMounted wird die Verbindung hergestellt und der Heartbeat-Timer gestartet und ein. Es wird angenommen das aus Sicherhietsgründen der 
// Browser die Verbindung nach einem Standby-Modus unterbricht und nicht zulässt, dass die Verbindung wiederhergestellt wird. 
// Oder es in dem jeweiligen Browser als Sicherheit mit einem Update hinzukommen kann. Mit einem page refresh wird dafür gesorgt, dass die Verbindung wiederhergestellt wird,
// obgleich es nicht die eleganteste Lösung ist, aber eine Lösung die funktioniert.
// Event-Listener für die Sichtbarkeit der Seite hinzugefügt. Beim onUnmounted wird der Timer und der Event-Listener entfernt.
// Die Funktion checkConnection überprüft die Verbindung und aktualisiert den lastHeartbeat-Wert, wenn die Verbindung erfolgreich ist. Wenn die Verbindung verloren geht, wird ein page refresh durchgeführt.


let lastHeartbeat = ref(new Date().getTime());

// Funktion, um die Verbindung zu überprüfen
const checkConnection = () => {
  console.log("Verbindung wird überprüft...");
  // Aktualisieren des lastHeartbeat bei erfolgreicher Überprüfung
  if (client.connected) lastHeartbeat.value = new Date().getTime();
  else {
    console.log("Verbindung verloren. Versuche erneut zu verbinden...");
    //Mach einen Pagerefresh wenn die Verbindung verloren geht
    location.reload();
  };
};

// Überprüfung bei Wiederaufnahme
const onResume = () => {
  const now = new Date().getTime();
  // Wenn mehr als 10 Sekunden seit dem letzten Heartbeat vergangen sind
  if (now - lastHeartbeat.value > 10000) {
    console.log("Möglicherweise im Standby gewesen. Verbindung wird überprüft...");
    checkConnection();
  }
};

onMounted(() => {
  // Heartbeat Timer
  const intervalId = setInterval(() => {
    checkConnection();
  }, 10000); // Alle 10 Sekunden

  // Event Listener für Browser
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      onResume();
    }
  });

  // Cleanup bei Komponentenzerstörung
  onUnmounted(() => {
    clearInterval(intervalId);
    document.removeEventListener('visibilitychange', onResume);
  });
});
//  code-Bereich zum überwachen ob sich das Gerät sich im Standby befand
</script>
