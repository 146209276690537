import { createApp } from 'vue';
import App from './App.vue';

import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/aura-light-green/theme.css' // Theme
import 'primevue/resources/primevue.min.css'; // Core CSS
import "primeicons/primeicons.css";// Icons
import 'primeflex/primeflex.css'; // Grid and spacing system

import { createPinia } from 'pinia';
import piniaPersist  from "pinia-plugin-persistedstate" //für localstorage pinia plugin

import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import Column from 'primevue/column';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import DialogService from 'primevue/dialogservice'
import Dropdown from 'primevue/dropdown';
import DynamicDialog from 'primevue/dynamicdialog';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import InputOtp from 'primevue/inputotp';
import Password from 'primevue/password';
import ScrollPanel from 'primevue/scrollpanel';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';

//import locale datei für deutschland für primevue
import de from '@/assets/de.json';

const pinia = createPinia();
pinia.use(piniaPersist ); //plugin zu pinia

const app = createApp(App);

// eslint-disable-next-line vue/multi-word-component-names
app.component('Button', Button);
// eslint-disable-next-line vue/multi-word-component-names
app.component('Calendar', Calendar);
app.component('ConfirmDialog', ConfirmDialog);
// eslint-disable-next-line vue/multi-word-component-names
app.component('Column', Column);
// eslint-disable-next-line vue/multi-word-component-names
app.component('Dialog', Dialog);
app.component('DataTable', DataTable);
// eslint-disable-next-line vue/multi-word-component-names
app.component('Dropdown', Dropdown);
app.component('DynamicDialog', DynamicDialog);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputOtp', InputOtp);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
// eslint-disable-next-line vue/multi-word-component-names
app.component('Password', Password);
// eslint-disable-next-line vue/multi-word-component-names
app.component('Toast', Toast);
app.component('ScrollPanel', ScrollPanel);

app.use(ConfirmationService);
app.use(DialogService);
app.use(ToastService);
app.use(PrimeVue);
app.use(PrimeVue, {
    ripple: true,
    locale:de.de,
  });
app.use(pinia);

app.mount('#app');